<template>
  <div class="px-20px pt-8px">
    <template v-if="listTree.length > 0">
      <div class="flex justify-between border-b border-ems-gray700">
        <a-tabs v-model:activeKey="activeTab" @change="onChangeTab($event)">
          <a-tab-pane
            v-for="item in tabGroups"
            :key="item.key"
            :tab="getTabName(item.tabName)"
          >
          </a-tab-pane>
        </a-tabs>
        <div
          class="flex flex-col flex-shrink-0 justify-center"
          title="Reset chart"
        >
          <div
            class="flex items-center px-8px py-9px bg-ems-gray600 cursor-pointer rounded-1/2"
            @click="resetChart"
          >
            <img
              src="../../../static/img/icon/ResetIconWhite.svg"
              alt="ResetIconWhite"
            />
          </div>
        </div>
      </div>
      <div v-if="isLoading">
        <cds-loading class="h-600px !items-start" />
      </div>
      <div v-else class="tree-chart">
        <vue-tree
          ref="tree"
          class="w-full"
          style="height: 600px"
          :dataset="currentTree"
          :config="treeConfig"
          linkStyle="straight"
        >
          <template v-slot:node="{ node, collapsed }">
            <span
              class="tree-node bg-ems-tag1"
              :class="{
                'bg-ems-instagram':
                  (node.children && node.children.length > 0) ||
                  collapsed ||
                  node.id === currentTree.mainNodeId,
              }"
              :title="node.name"
              >{{
                node.name.length > 20
                  ? `${node.name.substring(0, 20)}...`
                  : node.name
              }}</span
            >
          </template>
        </vue-tree>
      </div>
    </template>
    <template v-else>
      <Empty :description="t('common.no_data_displayed')" />
    </template>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import VueTree from '@ssthouse/vue3-tree-chart';
import '@ssthouse/vue3-tree-chart/dist/vue3-tree-chart.css';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import Empty from '@/components/empty/index.vue';

const { t } = useI18n();
const { state, dispatch } = useStore();
const listTree = computed(() => state.ioManagement.listTree);
const tabGroups = computed(() => state.ioManagement.tabGroups);
const activeTab = computed(() => state.ioManagement.activeTab);
const treeConfig = { nodeWidth: 200, nodeHeight: 200, levelHeight: 120 };
const tree = ref(null);
const currentTree = computed(() => state.ioManagement.currentTree);
const isLoading = ref(false);
const getTabName = (tabName) => {
  return (
    <div title={tabName}>
      {tabName.length > 20 ? `${tabName.substring(0, 20)}...` : tabName}
    </div>
  );
};
const resetChart = () => {
  tree.value.init();
};
const onChangeTab = (key) => {
  isLoading.value = true;
  setTimeout(() => {
    isLoading.value = false;
  }, 300);
  dispatch('ioManagement/changeTabTree', key);
};
</script>
<style>

.tree-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tree-node {
  display: inline-block;
  padding: 4px 16px;
  border-radius: 8px;
  text-align: center;
  line-height: 28px;
  color: #ffffff;
}

.tree-chart .tree-container .link {
  stroke: #555555 !important;
}

</style>
